import { Box, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useGetSalesCustomerQuery } from "../../../app/services/sales/customer/salesCustomer";
import {
  ISalesExpenseAdd,
  useAddSalesExpenseMutation,
} from "../../../app/services/sales/expense/salesExpense";
import MobileSaveHeader from "../../../components/common/mobileHeader/MobileSaveHeader";
import NumericFormatCustom from "../../../components/common/numericFormatCustom/NumericFormatCustom";
import SearchInput from "../../../components/common/searchInput/SearchInput";
import PageTitle from "../../../components/common/ui/typography/pageTitle/PageTitle";
import UserList from "../../../components/common/userList/UserList";
import { IUserListItem } from "../../../components/common/userList/UserListItem";
import { sales_routes } from "../../../constants/path";
import useUserSearchList from "../../../hooks/useUserSearchList";

const initialValues: ISalesExpenseAdd = {
  naqdsum: "",
  naqdusd: 0 /*doim 0*/,
  valyuta: 0 /*doim 0*/,
  bank: 0 /*doim 0*/,
  karta: 0 /*doim 0*/,
  client_id: "",
};

const validationSchema = yup.object<ISalesExpenseAdd>().shape({
  naqdsum: yup.number().required("Summa talab qilinadi"),
  client_id: yup.string().required("Mijoz talab qilinadi"),
});

const SalesExpenseAdd = () => {
  // State
  const [search, setSearch] = useState<string>("");

  // Navigator
  const navigate = useNavigate();

  // use UserSearch
  const [searchUser] = useUserSearchList();

  // API
  const { data, isLoading } = useGetSalesCustomerQuery();
  const [addData, { data: addExpenseDataRes }] = useAddSalesExpenseMutation();

  // Res useEffect
  useEffect(() => {
    if (addExpenseDataRes?.message) {
      if (addExpenseDataRes.success) {
        toast.success(addExpenseDataRes?.message);
        navigate(sales_routes.expense);
        formik.resetForm();
      } else {
        toast.error(addExpenseDataRes?.message);
      }
    }
  }, [addExpenseDataRes]);

  // Submit
  const handleSubmit = async (values: ISalesExpenseAdd) => {
    await addData(values);
  };

  // Formik
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  // useMemo
  const filterData = useMemo<IUserListItem[]>(() => {
    if (!data?.data) return [];
    return data.data.map((item) => ({
      key: item.id,
      name: item.fio,
      phone: item.telefon,
      editId: item.id,
      company: item.korxona,
    }));
  }, [data]);

  const list = useMemo<IUserListItem[]>(() => {
    return searchUser(filterData, search);
  }, [filterData, search, searchUser]);

  // Customer

  // Select customer
  const handleSelectCustomer = (val: string) => {
    formik.setFieldValue("client_id", val);
  };

  // handle cancel
  const handleCancel = () => {
    if (!formik.values.client_id) {
      navigate(sales_routes.expense);
    } else {
      formik.setFieldValue("client_id", "");
    }
  };

  return (
    <Box sx={{ pt: 4 }}>
      <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
        {/* Header */}
        <MobileSaveHeader
          onCancel={handleCancel}
          cancelLabel={!formik.values.client_id ? "Bekor qilish" : "Orqaga"}
        />

        {/* Title */}
        <PageTitle title="Mijozlar" />

        {/* Search */}
        <SearchInput value={search} setValue={setSearch} />

        {!formik.values.client_id ? (
          /* List */
          <UserList
            isLoading={isLoading}
            data={[...list]}
            onPress={handleSelectCustomer}
          />
        ) : (
          <Grid item xs={12} sm={6} mt={4}>
            <TextField
              name="naqdsum"
              id="naqdsum"
              size="small"
              label="Summa"
              value={formik.values.naqdsum}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.naqdsum && Boolean(formik.errors.naqdsum)}
              helperText={formik.touched.naqdsum && formik.errors.naqdsum}
              disabled={formik.isSubmitting}
              InputProps={{
                inputComponent: NumericFormatCustom as any,
              }}
              fullWidth
            />
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default SalesExpenseAdd;
