import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthRequired from "./components/common/authRequired/AuthRequired";
import MainOutlet from "./components/reactRouterDom/MainOutlet";
import { role_list } from "./constants/const";
import {
  admin_routes,
  agent_routes,
  auth_routes,
  sales_routes,
  store_routes,
} from "./constants/path";
import {
  AdminLayout,
  AgentLayout,
  AuthLayout,
  SalesLayout,
  StoreLayout,
} from "./layout";
import {
  AdminAgentBalance,
  AdminBalance,
  AdminGetMoneyFromAgentFrom,
  AdminGivingMoneyToAgent,
  AdminGivingMoneyToAgentAdd,
  AdminHome,
  AdminProducts,
  AdminReportsCustomer,
  AdminReportsCustomerDebt,
  AdminUsers,
  AgentGiveMonetToProviderAdd,
  AgentGiveMoneyToProvider,
  AgentGiveMoneyToProviderCheckSms,
  AgentHome,
  AgentProvider,
  AgentProviderAdd,
  AgentPurchase,
  AgentPurchaseAdd,
  AuthLogin, Page404,
  Profile,
  SalesCustomer,
  SalesCustomerAdd,
  SalesExpense,
  SalesHome,
  SalesOrder,
  SalesOrderAdd,
  SalesReport,
  StoreHome,
  StoreOrder,
  StorePreparation,
  StorePreparationAdd,
  StoreReception,
  StoreReceptionAdd
} from "./pages";
import SalesProfile from "./pages/sales/profile/SalesProfile";
import SalesExpenseAdd from "./pages/sales/expenseAdd/SalesExpenseAdd";

const Router: React.FC = () => {
  return (
    <Routes>
      <Route element={<MainOutlet />}>
        {/* Home */}
        <Route
          path="/"
          element={<Navigate to={auth_routes.login} replace={true} />}
        />

        {/* Auth */}
        <Route path={auth_routes.login} element={<AuthLayout />}>
          <Route index element={<AuthLogin />} />
        </Route>

        {/* Admin */}
        <Route element={<AuthRequired allowRoles={[role_list.admin]} />}>
          <Route path={admin_routes.home} element={<AdminLayout />}>
            <Route index element={<AdminHome />} />
            <Route path={admin_routes.user} element={<AdminUsers />} />
            <Route path={admin_routes.givingMoneyToAgent} element={<AdminGivingMoneyToAgent />} />
            <Route path={admin_routes.givingMoneyToAgentAdd} element={<AdminGivingMoneyToAgentAdd />} />
            <Route path={admin_routes.givingMoneyToAgentGet} element={<AdminGetMoneyFromAgentFrom />} />
            <Route path={admin_routes.agentBalance} element={<AdminAgentBalance />} />
            <Route path={admin_routes.balance} element={<AdminBalance />} />
            <Route path={admin_routes.reportsCustomer} element={<AdminReportsCustomer />} />
            <Route path={admin_routes.reportsCustomerDebt} element={<AdminReportsCustomerDebt />} />
            <Route path={admin_routes.products} element={<AdminProducts />} />
          </Route>
        </Route>

        {/* Agent */}
        <Route element={<AuthRequired allowRoles={[role_list.agent]} />}>
          <Route path={agent_routes.home} element={<AgentLayout />}>
            <Route index element={<AgentHome />} />
            <Route path={agent_routes.provider} element={<AgentProvider />} />
            <Route
              path={agent_routes.providerAdd}
              element={<AgentProviderAdd />}
            />
            <Route path={agent_routes.giveMoneyToProvider} element={<AgentGiveMoneyToProvider />} />
            <Route path={agent_routes.giveMoneyToProviderAdd} element={<AgentGiveMonetToProviderAdd />} />
            <Route path={agent_routes.giveMoneyToProviderCheckSms} element={<AgentGiveMoneyToProviderCheckSms />} />
            <Route path={agent_routes.purchase} element={<AgentPurchase />} />
            <Route
              path={agent_routes.purchaseAdd}
              element={<AgentPurchaseAdd />}
            />
            <Route path={agent_routes.profile} element={<Profile />} />
          </Route>
        </Route>

        {/* Store */}
        <Route element={<AuthRequired allowRoles={[role_list.store]} />}>
          <Route path={store_routes.home} element={<StoreLayout />}>
            <Route index element={<StoreHome />} />
            <Route path={store_routes.order} element={<StoreOrder />} />
            <Route path={store_routes.orderHistory} element={<StoreOrder />} />
            <Route path={store_routes.reception} element={<StoreReception />} />
            <Route
              path={store_routes.receptionAdd}
              element={<StoreReceptionAdd />}
            />
            <Route
              path={store_routes.preparation}
              element={<StorePreparation />}
            />
            <Route
              path={store_routes.preparationAdd}
              element={<StorePreparationAdd />}
            />
            <Route path={store_routes.profile} element={<Profile />} />
          </Route>
        </Route>

        {/* Sales */}
        <Route element={<AuthRequired allowRoles={[role_list.sales]} />}>
          <Route path={sales_routes.home} element={<SalesLayout />}>
            <Route index element={<SalesHome />} />
            <Route path={sales_routes.home} element={<SalesHome />} />
            <Route path={sales_routes.expense} element={<SalesExpense />} />
            <Route path={sales_routes.expenseAdd} element={<SalesExpenseAdd />} />
            <Route path={sales_routes.order} element={<SalesOrder />} />
            <Route path={sales_routes.orderAdd} element={<SalesOrderAdd />} />
            <Route path={sales_routes.order} element={<SalesOrder />} />
            <Route path={sales_routes.report} element={<SalesReport />} />
            <Route path={sales_routes.customer} element={<SalesCustomer />} />
            <Route path={sales_routes.customerAdd} element={<SalesCustomerAdd />} />
            <Route path={sales_routes.profile} element={<SalesProfile />} />
          </Route>
        </Route>

        {/* Page not found */}
        <Route path={'*'} element={<Page404 />} />
      </Route>
    </Routes>
  );
};

export default Router;
