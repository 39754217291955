// Auth
export const auth_routes = {
  login: "/login",
};

// Admin
export const admin_routes = {
  home: "/admin",
  user: "/admin/user",
  givingMoneyToAgent: "/admin/givingMoneyToAgent",
  givingMoneyToAgentAdd: "/admin/givingMoneyToAgentAdd",
  givingMoneyToAgentGet: "/admin/givingMoneyToAgentGet",
  agentBalance: "/admin/agentBalance",
  balance: "/admin/balance",
  reportsCustomer: "/admin/reportsCustomer",
  reportsCustomerDebt: "/admin/reportsCustomerDebt",
  products: "/admin/products",
};

// Agent
export const agent_routes = {
  home: "/agent",
  provider: "/agent/provider",
  providerAdd: "/agent/providerAdd",
  providerEdit: "/agent/providerEdit/:providerId",
  giveMoneyToProvider: "/agent/giveMoneyToProvider",
  giveMoneyToProviderAdd: "/agent/giveMoneyToProviderAdd",
  giveMoneyToProviderCheckSms: "/agent/giveMoneyToProviderCheckSms/:providerId",
  purchase: "/agent/purchase",
  purchaseAdd: "/agent/purchaseAdd",
  profile: "/agent/profile",
};

// Store
export const store_routes = {
  home: "/store",
  order: "/store/order",
  orderHistory: "/store/order/history",
  reception: "/store/reception",
  receptionAdd: "/store/receptionAdd",
  preparation: "/store/preparation",
  preparationAdd: "/store/preparation/:orderId/add",
  profile: "/store/profile",
};

// Sales
export const sales_routes = {
  home: "/sales",
  expense: "/sales/expense",
  expenseAdd: "/sales/expenseAdd",
  order: "/sales/order",
  orderAdd: "/sales/orderAdd",
  report: "/sales/report",
  customer: "/sales/customer",
  customerAdd: "/sales/customerAdd",
  profile: "/sales/profile",
};
