import { lazy } from "react";

// Page
export const SalesHome = lazy(() => import("./home/SalesHome"));
export const SalesExpense = lazy(() => import("./expense/SalesExpense"));
export const SalesOrder = lazy(() => import("./order/SalesOrder"));
export const SalesOrderAdd = lazy(() => import("./orderAdd/SalesOrderAdd"));
export const SalesReport = lazy(() => import("./report/SalesReport"));
export const SalesCustomer = lazy(() => import("./customer/SalesCustomer"));
export const SalesCustomerAdd = lazy(() => import("./customerAdd/SalesCustomerAdd"));
export const SalesProfile = lazy(() => import("./profile/SalesProfile"));
