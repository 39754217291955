import { lazy } from "react";

// Pages
export const AgentHome = lazy(() => import('./home/AgentHome'))
export const AgentProvider = lazy(() => import('./provider/AgentProvider'))
export const AgentProviderAdd = lazy(() => import('./providerAdd/AgentProviderAdd'))
export const AgentGiveMoneyToProvider = lazy(() => import('./giveMoneyToProvider/AgentGiveMoneyToProvider'))
export const AgentGiveMonetToProviderAdd = lazy(() => import('./giveMonetToProviderAdd/AgentGiveMonetToProviderAdd'))
export const AgentGiveMoneyToProviderCheckSms = lazy(() => import('./giveMoneyToProviderCheckSms/AgentGiveMoneyToProviderCheckSms'))
export const AgentPurchase = lazy(() => import('./purchase/AgentPurchase'))
export const AgentPurchaseAdd = lazy(() => import('./purchaseAdd/AgentPurchaseAdd'))