/* ======================
          LOCAL STORAGE
====================== */
export const localTokenKey = "apiprivatetemplocaltokenkey";

/* ======================
          ROLE
====================== */
export const role_list = {
  admin: "admin",
  agent: "zakup",
  sales: "sotuv",
  store: "sklad",
};

/* ======================
          TABLE
====================== */
export const tableLocalization =  {
  search: 'Qidirish',
  showHideSearch: "Hamma ustun bo'yicha qidirish",
  showHideColumns: "Ustunlarni tahrirlash",
  hideAll: "Yashirish",
  showAll: "Ko'rsatish",
  toggleDensity: "Qatorlar kengligini o'zgartirish",
  and: "e",
  showHideFilters: "Ustunlar bo'yicha filterlash",
  cancel: "Cancelar",
  changeFilterMode: "Alterar o modo de filtro",
  changeSearchMode: "Alterar o modo de pesquisa",
  clearFilter: "Tozalash",
  clearSearch: "Tozalash",
  clearSort: "Sortni tozalash",
  sortByColumnAsc: "A-z",
  sortByColumnDesc: "Z-a",
  clickToCopy: "Clique para copiar",
  filterByColumn: 'Filter',
  hideColumn: 'Yashirish'
}

