import { IDate } from "../types/api";

export const main_url = "https://murod-maslo.uz/api";

// AUTH
const auth_api = {
  login: "/user/login.php",
  show: "/user/get-user.php",
};

// ADMIN
const admin_api = {
  // users
  users_get: "/admin/get-user.php",
  users_add: "/admin/add-user.php",
  users_update: "/admin/get-user.php",

  // giving money to agent
  giving_money_to_agent_get: "/admin/get-zakup-money-history.php",
  agent_balance_get: "/admin/get-zakup-balans.php",
  giving_money_to_agent_add: "/admin/give-money-zakup.php",
  get_money_from_agent_add: "/admin/take-money-zakup.php",

  // balance
  balance_get: "/admin/get-balans.php",
  balance_history_get: "/admin/get-added-balans.php",
  balance_add: "/admin/add-balans.php",
  balance_substract_add: "/admin/take-balans.php",

  // reports
  reports_customer_get: (date: IDate, customer_id: string) =>
    `/sotuv/get-mijoz-report.php?client_id=${customer_id}&sana1=${date.start}&sana2=${date.end}`,
  reports_customer_debt_get: (date: IDate, customer_id: string) => `/sotuv/get-mijozlar-hisobot-qarzdorlik.php?sana1=${date.start}&sana2=${date.end}&dostvka_id=null`
  // products
};

// AGENT
const agent_api = {
  // balance
  balance_get: "/zakup/get-balans.php",
  balance_products_get: "/zakup/get-zakup-products.php",
  
  // provider
  provider_get: "/zakup/get-taminotchi.php",
  provider_get_by_id: (providerId:string) =>  `/zakup/get-taminotchi.php?id=${providerId}`,
  provider_add: "/zakup/add-taminotchi.php",
  provider_put: (providerId: string) =>
    `/zakup/edit-taminotchi.php?id=${providerId}`,

  // give money to provider
  give_money_to_provider_get:"/zakup/get-taminotchi-pay-history.php",
  give_money_to_provider_add: "/zakup/pay-taminotchi.php",
  give_money_to_provider_check_sms_add: "/kassir/check-sms-code.php", 
    
  // region
  region_get: "/zakup/get-viloyat.php",
  district_get_by_region: (regionId: string) =>
    `/zakup/get-tuman.php?viloyat_id=${regionId}`,

  // purchase
  purchase_get: "/zakup/get-krim.php",
  purchase_add: "/zakup/add-krim.php",
};

// STORE
const store_api = {
  // zakup
  agent_get: "/sklad/get-zakup-list.php",

  // spare
  spare_get: "/sklad/get-products.php",
  spare_chart_get: "/sklad/get-products-chart.php",

  // reception
  reception_cargo_get: "/sklad/get-zakup-krim.php",
  reception_cargo_add: "/sklad/add-zakup-krim.php",

  // order
  order_from_sales_get: "/sklad/get-sotuv-orders-list.php",
  order_from_sales_get_by_id: (orderId: string) =>
    `/sklad/get-doing-orders.php?id=${orderId}`,
  order_from_sales_confirm_put: (orderId: string) =>
    `/sklad/confirm-sotuv-order.php?id=${orderId}`,
  order_from_sales_confirmed_get: "/sklad/get-doing-orders.php",
  // order process
  order_process_add: "/sklad/give-polka-product.php",
  order_process_end: "/sklad/end-order.php",
  order_completed_get: "/sklad/get-sale-done-orders.php",
};

// SALES
const sales_api = {
  // chart
  chart_get: "/sklad/get-products-chart.php",

  // order
  order_get: "/sotuv/get-sale-orders.php",
  order_add: "/sotuv/add-sale-order.php",
  order_end_add: "/sotuv/end-order.php",

  // customer
  customer_category_get: "/sotuv/get-client-category.php",
  customer_get: "/sotuv/get-clients.php",
  customer_add: "/sotuv/add-client.php",
  customer_put: (customerId: string) =>
    `/sotuv/edit-client.php?id=${customerId}`,

  // spare
  spare_get: "/sotuv/get-products.php",

  // expense
  expense_get: "/sotuv/get-qarz-yechish-history.php",
  expense_add: "/sotuv/qarz-olish.php",
};

export { admin_api, agent_api, auth_api, store_api, sales_api };
